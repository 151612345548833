.certificate {
  @media #{$mq-small} {
    flex-direction: column;
  }
  a {
    text-decoration: none;
    &:visited {
      color: $black;
    }
  }
  .left {
    @media #{$mq-small} {
      width: 100% !important;
    }
  }
  .right {
    @media #{$mq-small} {
      width: 100% !important;
      .usedAmount-wrapper {
        width: 100% !important;
      }
      .button-wrapper {
        text-align: center;
        margin-top: 40px;
        button {
          max-width: 400px;
        }
      }
    }
  }
  .charge-container {
    display: flex;
    .usedAmount-wrapper {
      width: 250px;
      > div {
        width: 100%;
      }
    }
  }
}
