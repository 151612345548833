.login-wrapper {
  display: flex;
  > div {
    width: 50%;
  }
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 80px;
    background-color: #000;
    h3 {
      color: #fff;
    }
    .error {
      color: #fff;
      font-size: 0.8rem;
      padding-bottom: 10px;
    }
  }
  .images {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media screen and (max-width: 1024px) {
    .login {
      padding: 10px 20px;
    }
  }
  @media screen and (max-width: 650px) {
    .images {
      display: none;
    }
    > div {
      width: 100%;
    }
  }
}
