$darkblue: #17255a;
$blue: #33a1fd;
$green: #4caf50;
$red: #ef3054;
$grey: #8a95a5;
$black: #020202;
$primary-grey: #363535;
$secondary-grey: #1f1f1f;

$mainLayout: 1000px;
$bp-small: 48em; // 768px
$mq-small: "(max-width: #{$bp-small})";
