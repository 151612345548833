html,
body {
  color: $black;
}
html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

strong {
  color: $black;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}
