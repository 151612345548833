.loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text-wrapper {
    margin-top: 5px;
    font-style: italic;
    font-size: 10px;
  }
}
