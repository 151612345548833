input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  background-color: #fff;
  border: none;
  padding: 10px;
  color: $primary-grey;
}
