.languageselector-wrapper {
  position: absolute;
  border-radius: 5px;
  right: 10px;
  bottom: 10px;
  width: 120px;
  display: flex;
  overflow: hidden;
  *  {
    color: white !important;
  }
  .MuiSelect-selectMenu-10 {
    color: white !important;
  }
  i {
    color: white;
    font-size: 25px;
    margin: auto;
    padding-left: 10px;
  }
  .MuiInput-underline-17:after {
    border-bottom: 2px solid $darkblue !important;
  }
  @media screen and (max-width: 900px) {
    position: relative;
    top: auto;
    right: auto;
    margin: 50px auto 0;
    padding-bottom: 30px;
  }
}
