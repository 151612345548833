.ReactTable {
  background-color: #fff;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 60px;
  margin-bottom: 60px;
  .rt-table {
    padding: 0 60px;
    .-header {
      box-shadow: none !important;
      color: #535353;
      padding-top: 20px;
      .rt-th {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        &:focus {
          outline: 0;
        }
      }
    }
    .-filters {
      padding-bottom: 20px;
      input {
        margin: 0;
      }
    }
    .rt-tbody {
      padding-bottom: 20px;
      .rt-tr {
        padding: 5px 0;
        cursor: pointer;
        .rt-td {
          color: $primary-grey;
          font-size: 14px;
        }
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
  .pagination-bottom {
    background-color: #e3e3e3;
    padding: 20px 40px;
    .-pagination {
      box-shadow: none;
      border: none;
      .-previous,
      .-next {
        button {
          background-color: $primary-grey;
          border-radius: 50px;
          color: #fff;
          padding: 12px;
          &:disabled {
            background-color: #fff;
            color: $primary-grey;
          }
        }
      }
    }
    .-center {
      .-pageInfo {
        color: #020202;
        .-pageJump {
          input {
            color: #020202;
            margin-bottom: 0;
            border: none;
            background-color: #e3e3e3;
            font-weight: bold;
            padding: 0;
            padding-left: 10px;
          }
        }
        .-totalPages {
          padding: 0 20px;
        }
      }
    }
  }
}
