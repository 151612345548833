.print-btn {
  text-align: right;
  button {
    color: #fff;
    .print-label {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 650px) {
    text-align: center;
    margin-bottom: 50px;
  }
  @media print {
    display: none;
  }
}
