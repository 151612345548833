.gift-certificate {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  width: 650px;
  min-width: 650px;
  box-sizing: border-box;
  border: 1px solid #000;
  display: flex;
  overflow: hidden;
  .left {
    background-color: #fff;
    padding: 25px;
    box-sizing: border-box;
    width: 225px;
    max-width: 225px;
    display: flex;
    align-items: center;
    .company {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      .logo {
        width: 100%;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .right {
    padding: 25px 80px;
    background-color: $black;
    color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    .head {
      border-bottom: 2px solid #fff;
      padding-bottom: 20px;
      font-size: 24px;
      margin: 0;
      text-align: center;
      .title {
        font-weight: 500;
      }
    }
    .amount-wrapper {
      border-bottom: 2px solid #fff;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding-top: 12px;
      text-align: center;
      .amount {
        font-size: 50px;
        font-weight: bold;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 0.8rem;
      .customer {
        font-weight: 100;
        p {
          margin: 0;
        }
        .to {
          margin-bottom: 5px;
        }
        .from {
          margin-bottom: 5px;
        }
      }
      .id {
        align-self: flex-end;
      }
    }
    @media print {
      color: #000;
      .head {
        border-bottom: 2px solid #000;
      }
      .amount-wrapper {
        border-bottom: 2px solid #000;
      }
    }
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    width: 100% !important;
    min-width: auto !important;
    height: auto !important;
    min-height: auto !important;
    right: 0;
    transform: translate(0, -50%);
    .left {
      padding: 0;
      width: 100%;
      max-width: 100%;
      text-align: center;
      .company {
        width: 100%;
        .logo {
          height: 150px;
          text-align: center;
        }
      }
    }
    .right {
      flex: auto;
      padding: 25px;
      .head {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
