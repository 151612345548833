.buy-form {
  h1 {
    text-align: center;
  }
  img {
    display: block;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 225px;
  }
  .img-placeholder {
    min-height: 225px;
    min-width: 225px;
  }
}
