.centered-container {
  width: $mainLayout;
  max-width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 60px;
  background-color: #fff;
  padding: 40px 80px;
  margin-bottom: 100px;
  &.no-padding {
    padding: 0;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
}
