button {
  -webkit-appearance: none;
  background-color: $primary-grey;
  border: none;
  border-radius: 50px;
  color: #fff;
  padding: 10px 30px;
  font-size: 0.6rem;
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }
}
