.upper-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $mainLayout;
  margin: auto;
  color: #fff;
  text-transform: uppercase;
  margin-top: 80px;
  margin-bottom: 80px;
  @media #{$mq-small} {
    flex-direction: column-reverse;
  }
  h1 {
    @media #{$mq-small} {
      text-align: center;
    }
    img {
      padding-left: 40px;
      @media #{$mq-small} {
        display: none;
      }
    }
  }
  .menu {
    button {
      color: #fff;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
}
