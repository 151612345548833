.full-bg {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  background-color: $primary-grey;
  position: relative;
  overflow: auto;
  &.min-heigh {
    min-height: 940px;
  }
  .content-wrapper {
    background-color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    width: 500px;
    padding: 50px;
    h1 {
      margin-top: 0;
    }
    &.center {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
    }
    &:not(.center) {
      margin: 75px auto;
    }
    .hide {
      visibility: hidden !important;
      height: 0;
    }
    .error {
      color: $red;
    }
    .msg-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      a {
        color: $secondary-grey;
        text-decoration: none;
        font-style: italic;
      }
      svg {
        font-size: 60px;
        color: $green;
      }
      &.error {
        svg {
          color: $red;
        }
      }
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      > div {
        flex: 1;
      }
    }
    .spacer {
      margin-top: 50px;
    }
    .disclaim {
      color: $grey;
      text-align: center;
      font-style: italic;
      margin-bottom: 20px;
      p {
        margin: 0;
        line-height: 0.8rem;
        font-size: 0.8rem;
      }
    }
    .button-wrapper {
      display: flex;
      > * {
        flex: 1;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding-top: 50px;
    .content-wrapper {
      position: relative;
      transform: initial;
      top: auto;
      right: auto;
      margin: auto;
      box-sizing: border-box;
      width: 98%;
      padding: 30px;
    }
  }
}
