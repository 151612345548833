.container-sidebar {
  display: flex;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.2);
  color: $primary-grey;
  h2 {
    margin-top: 0;
  }
  .left {
    padding: 50px;
    width: 70%;
    background-color: #fff;
    .gc-header {
      display: flex;
    }
    .gc-people {
      display: flex;
      @media #{$mq-small} {
        flex-direction: column;
      }
      .buyer {
        padding-right: 100px;
        @media #{$mq-small} {
          padding-right: 0;
          margin-bottom: 15px;
        }
      }
    }
    .gc-people,
    .gc-header {
      border-bottom: 1px solid rgba($grey, 0.2);
      padding: 30px 0;

      p {
        margin: 5px 0;
      }
    }
  }
  .right {
    color: #fff;
    background-color: $secondary-grey;
    width: 30%;
    padding: 40px;
    display: flex;
    flex-direction: column;
  }
  .charge-container {
    .usedAmount-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 80%;
        margin-bottom: 0;
      }
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #fff;
    }
  }
  .balance-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .button-wrapper {
    margin-top: auto;
    button {
      width: 100%;
    }
  }
}
